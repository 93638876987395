import React, { useState, useEffect } from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Content from '../components/Content';
import Seo from '../components/SEO';

import {
  resourcesData, resourcesOptions, improvementAreasOptions, improvementAreasData, getFlag,
} from '../utils/data';
import { randomElements } from '../utils';
import Ad from '../components/Ad';
import CenteredContent from '../components/CenteredContent';

function Resources() {
  const [resources, setResources] = useState([...resourcesData]);
  const [improvementResources, setImprovementResources] = useState([]);

  const findResourceById = (id) => {
    const filteredResources = resourcesData
      .filter((element) => element.id === Number(id));
    setResources(filteredResources);
  };

  const filterImprovementResourceById = (improvementArea) => {
    setImprovementResources([]);
    const filteredResources = improvementAreasData.filter(({ skills }) => skills.includes(improvementArea));
    const elements = randomElements(filteredResources, 3);
    setImprovementResources(elements);
  };

  useEffect(() => {
    findResourceById(1);
    filterImprovementResourceById('reading');
  }, []);

  return (
    <Layout>
      <Seo />
      <Header title="Recursos" />
      <Content>
        <p>
          Esta recopilación de recuros, que incluyen: audio, texto, vídeos,
          te puden ayudar con español, italiano, alemán, inglés y latín.
        </p>
      </Content>

      <CenteredContent>
        <Ad />
      </CenteredContent>

      <Content>
        <form className="resources-form">
          <label htmlFor="standard-select">Quiero mejorar</label>
          <div className="select">
            <select
              id="standard-select"
              onChange={({ target }) => filterImprovementResourceById(target.value)}
            >
              {improvementAreasOptions.map(({ id, name }) => (<option key={id} value={id}>{name}</option>))}
            </select>
          </div>
        </form>

        <ul>
          {improvementResources.map(({ name, url, languages }) => (
            <li key={name}>
              <a className="link" href={url} target="_blank" rel="noreferrer">{name}</a>
              {languages.map((name) => getFlag(name)).join('')}
            </li>
          ))}
        </ul>

        <form className="resources-form">
          <label htmlFor="standard-select">Selecciona un tipo de recurso:</label>
          <div className="select">
            <select id="standard-select" onChange={(event) => findResourceById(event.target.value)}>
              {resourcesOptions.map(({ id, name }) => (<option key={id} value={id}>{name}</option>))}
            </select>
          </div>
        </form>

        {resources.map(({ type, languages }) => (
          <div key={type}>
            <h2>{type}</h2>
            {languages.map(({ flag, resources }) => (
              <div key={type + flag}>
                <span className="flag-emoji" role="img" aria-label="spain-flag">{flag}</span>
                <ul>
                  {resources.map(({ name, url }) => (
                    <li key={name}>
                        <a
                            className="link" href={url} target="_blank"
                            rel="noreferrer"
                          >
                            {name}
                          </a>
                      </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ))}
      </Content>
    </Layout>
  );
}

export default Resources;
